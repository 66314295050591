<template>
  <v-container fluid>
    <v-row>
      <v-col
        xl="2"
        lg="3"
        md="4"
        cols="12"
      class="ma-0 pa-0">
        <v-virtual-scroll
          :height="height"
          :items="items"
          item-height="50"
          :bench="3"
          class="grey lighten-4"
        >
          <template #default="{item, index}">
            <v-divider v-if="item.type === 'title' && index > 0" />
            <template v-if="item.type === 'title'">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><b style="font-size:120%;">{{ item.text }}</b></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>{{ item.final | float2 }}</v-list-item-action>
              </v-list-item>
            </template>
            <template v-else-if="item.type === 'turner'">
              <v-list-item @click="open(item)" :class="{red: result.data && item.tid === result.tid && item.person._id === result.pid, 'lighten-3': true}">
                <v-list-item-content>
                  <v-list-item-title style="text-indent:16px;">{{ item.person | person }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>{{ item.result.final | float2 }}</v-list-item-action>
              </v-list-item>
            </template>
            <v-divider />
          </template>
        </v-virtual-scroll>
      </v-col>
      <v-col
        v-if="inline && result.open"
        xl="10"
        lg="9"
        md="8"
      >
        <v-radio-group
          v-if="istSprung(did, df)"
          v-model="result.subpart"
          row
        >
          <v-radio label="Sprung 1" :value="1" />
          <v-radio label="Sprung 2" :value="2" />
        </v-radio-group>

        <ergebnis :tid="result.tid" :pid="result.pid" :subpart="result.subpart" :did="did" :df="df" :e="e" @del="del" @save="save" @close="close" />
      </v-col>
      <base-material-dialog
        v-else
        v-model="result.open"
        icon="far fa-poll-h"
        :title="`Wertung eingeben`"
        color="primary"
        @esc="close"
      >
        <v-radio-group
          v-if="istSprung(did, df)"
          v-model="result.subpart"
          row
        >
          <v-radio label="Sprung 1" :value="1" />
          <v-radio label="Sprung 2" :value="2" />
        </v-radio-group>

        <ergebnis v-if="result.open" :tid="result.tid" :pid="result.pid" :subpart="result.subpart" :did="did" :df="df" :e="e" @del="del" @save="save" @close="close" />
      </base-material-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import { useCalc } from '../../../plugins/calc'
import gql from 'graphql-tag'

export default {
  name: 'geraet',

  components: {
    Ergebnis: () => import('./ergebnis')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    did: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    result: {
      open: false,
      id: null,
      data: null,
      tid: null,
      pid: null,
      subpart: null,
      person: null
    },
    windowHeight: window.innerHeight,
    documentHeight: -1,
    resizeObserver: null
  }),

  computed: {
    d () {
      return this.df?.find(d => d._id === this.did) || {}
    },
    height () {
      if (this.f) return this.windowHeight - 210
      const tmp = Math.max(this.windowHeight, this.documentHeight)
      return tmp < 850 ? 500 : tmp - 350
    },
    items () {
      return this.mannschaftenangeraet(this.e, this.df, this.did).map(t => {
        return [
          { type: 'title', text: `${t.club.name} (G${t.group || 0})`, final: this.mannschaftgeraetergebnis(this.e, this.r, this.did, this.teamid(t)) },
          ...t?.athletes.map(a => ({
            type: 'turner',
            person: a.person,
            tid: this.teamid(t),
            result: this.r?.find(r => r.teamId === this.teamid(t) && r._discipline === this.did && r._person === a.person._id && !r.subpart) || {}
          }))
        ]
      }).reduce((acc, curr) => {
        acc.push(...curr)
        return acc
      }, [])
    },
    inline () {
      return !!['lg', 'xl'].find(b => b === this.$vuetify.breakpoint.name)
    },
    ori () {
      return this.subparts.find(r => !r.subpart) || null
    },
    main () {
      return this.r?.find(r => r._id === this.result.main) || null
    },
    subparts () {
      return this.r?.filter(r => r.teamId === this.result.tid && r._discipline === this.did && r._person === this.result.pid) || []
    }
  },

  methods: {
    open (item) {
      this.result.data = item?.result
      this.result.tid = item?.tid
      this.result.pid = item?.person?._id
      this.result.person = item?.person
      this.result.subpart = this.istSprung(this.did, this.df) ? 1 : null
      this.result.open = !!item
    },
    onResize () {
      this.windowHeight = window.innerHeight
      this.documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - 50
    },
    close () {
      this.result.open = false
      this.open(null)
    },
    async save (data, nr) {
      if (nr === undefined) nr = this.result.subpart || 0
      const ori = nr === 0 ? this.ori : this.getsubpart(nr)

      if (!data) return

      const neu = ori ? {} : data

      if (ori) {
        if (ori.dscore !== data.dscore) neu.dscore = data.dscore
        if (ori.escore !== data.escore) neu.escore = data.escore
        if (ori.e1 !== data.e1) neu.e1 = data.e1
        if (ori.e2 !== data.e2) neu.e2 = data.e2
        if (ori.penalty !== data.penalty) neu.penalty = data.penalty
        if (ori.deduction !== data.deduction) neu.deduction = data.deduction
        if (ori.final !== data.final) neu.final = data.final
      }

      if (Object.keys(neu).length === 0) {
        if (this.istSprung(this.did, this.df)) {
          if (nr !== null) {
            if (this.result.subpart === 1) this.result.subpart = 2
            else this.close()
            return
          }
        } else {
          return this.close()
        }
      }

      neu.subpart = data.subpart

      await this.mutate({
        mutation: gql`mutation($event: UUID!, $teamId: String!, $person: UUID!, $discipline: UUID!, $subpart: Int, $dscore: Float, $escore: Float, $e1: Float, $e2: Float, $penalty: Float, $deduction: Float, $final: Float) {
            StbLtf2024TcTeamAthleteResultSave(event: $event, teamId: $teamId, person: $person, discipline: $discipline, subpart: $subpart, dscore: $dscore, escore: $escore, e1: $e1, e2: $e2, penalty: $penalty, deduction: $deduction, final: $final) { _id }
          }`,
        variables: {
          ...neu,
          event: this.e._id,
          teamId: this.result.tid,
          person: this.result.pid,
          discipline: this.did
        }
      })

      if (this.istSprung(this.did, this.df) && nr !== 0) {
        this.savesprung(data, nr)
      }

      if (this.istSprung(this.did, this.df)) {
        if (nr !== 0) {
          if (this.result.subpart === 1) this.result.subpart = 2
          else this.close()
        }
      } else {
        return this.close()
      }
    },
    async del () {
      if (await this.$root.$children[0].$refs.confirm.open('Wertung löschen?', 'Die Wertung kann nicht wiederhergestellt werden!')) {
        await this.mutate({
          mutation: gql`mutation($event: UUID!, $teamId: String!, $person: UUID!, $discipline: UUID!, $subpart: Int) {
            StbLtf2024TcTeamAthleteResultDelete(event: $event, teamId: $teamId, person: $person, discipline: $discipline, subpart: $subpart)
          }`,
          variables: {
            event: this.e._id,
            teamId: this.result.tid,
            person: this.result.pid,
            discipline: this.did,
            subpart: this.result.subpart
          }
        })

        if (this.istSprung(this.did, this.df)) {
          this.savesprung(null, this.result.subpart)
        }
      }
    },
    getsubpart (nr) {
      return this.subparts?.find(s => s.subpart === nr)
    },
    async savesprung (data, nr) {
      const main = this.getsubpart(null)
      const p1 = nr === 1 ? data : this.getsubpart(1)
      const p2 = nr === 2 ? data : this.getsubpart(2)
      const f1 = p1?.final
      const f2 = p2?.final

      const f = main?.final

      if (f1 !== undefined || f2 !== undefined) {
        let target = 1
        if (f1 !== undefined && f2 !== undefined) target = f1 >= f2 ? 1 : 2
        else if (f1 !== undefined) target = 1
        else target = 2

        const tmp = JSON.parse(JSON.stringify(target === 1 ? p1 : p2))
        tmp.subpart = null

        if (f !== tmp.final) {
          await this.save(tmp, 0)
        }
      } else {
        await this.mutate({
          mutation: gql`mutation($event: UUID!, $teamId: String!, $person: UUID!, $discipline: UUID!, $subpart: Int) {
            StbLtf2024TcTeamAthleteResultDelete(event: $event, teamId: $teamId, person: $person, discipline: $discipline, subpart: $subpart)
          }`,
          variables: {
            event: this.e._id,
            teamId: this.result.tid,
            person: this.result.pid,
            discipline: this.did,
            subpart: null
          }
        })
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.resizeObserver = new ResizeObserver(this.onResize)
      this.resizeObserver.observe(document.body)
      this.onResize()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    this.resizeObserver.disconnect()
  }
}
</script>

<style scoped>

</style>
