import { EventStatus, StbLtf2024TcMode, GenderType } from '@/enum'

export const useCalc = () => {
  const istSprung = (did, df) => {
    return !!df.filter(d => !!d.name.match(/Sprung( \([mw]\))?/)).find(d => d._id === did)
  }

  const modus = (e) => {
    return StbLtf2024TcMode.find(m => m.value === e.mode)?.text || e.mode
  }

  const status = (e) => {
    return EventStatus.find(m => m.value === e.status)?.text || e.status
  }

  const gender = (e) => {
    return GenderType.find(m => m.value === e.gender)?.text || e.gender
  }

  const geraete = (e, df, all) => {
    return e?.disciplines?.map(d => ({
      ...d,
      name: df?.find(d2 => d2._id === d._discipline)?.name,
      shortName: df?.find(d2 => d2._id === d._discipline)?.shortName,
      regular: df?.find(d2 => d2._id === d._discipline)?.regular
    }))?.filter(d => all || d.regular)?.sort((a, b) => a.order < b.order ? -1 : 1) || []
  }

  const geraetnr = (e, df, did) => {
    return geraete(e, df).findIndex(d => d._discipline === did)
  }

  const teamid = (team) => {
    return team?.identifiers?.find(id => id.name === 'gymnet.teamId')?.value || ''
  }

  const mannschaftsgeraetefolge = (e, df, tid) => {
    const disc = geraete(e, df)
    const start = e?.teams?.find(t => teamid(t) === tid)?._startdiscipline

    const index = disc.findIndex(d => d._discipline === start)

    if (index === -1) return disc
    const tmp = disc.splice(0, index)
    return [...disc, ...tmp]
  }

  const mannschaftenangeraet = (e, df, did) => {
    const t = e?.teams?.slice()
      ?.map(t => ({
        ...t,
        geraetefolge: mannschaftsgeraetefolge(e, df, teamid(t)),
        did,
        df: mannschaftsgeraetefolge(e, df, teamid(t)).findIndex(d => d._discipline === did) + t.group * 100
      }))
      ?.sort((a, b) => {
        if (a.df !== b.df) return a.df < b.df ? -1 : 1
        return a.order < b.order ? -1 : 1
      })
    if (!t || t.length === 0) return []

    return t
  }

  const mannschaftgeraetergebnis = (e, r, did, tid) => {
    const results = r.filter(r => r._event === e._id && r.teamId === tid && r._discipline === did && !r.subpart)?.map(r => r.final)
    return results?.sort((a, b) => parseInt(a) < parseInt(b) ? 1 : -1)?.[0] || 0
  }

  const mannschaftergebnis = (e, r, tid) => {
    const results = e.disciplines.map(d => mannschaftgeraetergebnis(e, r, d._discipline, tid))

    if (e.gender === 'mixed') {
      return results.sort((a, b) => a > b ? -1 : 1).slice(0, 6).reduce((acc, curr) => acc + curr, 0)
    } else {
      return results.reduce((acc, curr) => acc + curr, 0)
    }
  }

  return {
    istSprung,
    modus,
    status,
    gender,
    geraete,
    geraetnr,
    teamid,
    mannschaftsgeraetefolge,
    mannschaftenangeraet,
    mannschaftgeraetergebnis,
    mannschaftergebnis
  }
}
